<template>
    <div>  
        <!-- <HousingNav/> -->
          
        <b-overlay :show="isLoadingData || isUpdatingRentalPool">
            <b-card>
                <div class="head-rental">
                    <h1>Actualizar Pool de Rentas</h1>
                    <b-button
                        :to="{ name: 'rental-pool'}"               
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"                                  
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />  
                    </b-button>
                </div>                 
                <ValidationObserver ref="createRentalPool" v-slot="{ invalid }" v-if="rentalPoolData" >
                    <b-form @submit.prevent="update">
                        <b-row>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="nombre">
                                    <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="text"
                                        v-model="rentalPoolData.name"
                                        :state="errors[0] ? false : valid ? true : null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                            <ValidationProvider name="resort" rules="required">
                                <b-form-group label="Resort" slot-scope="{ valid, errors }">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="rentalPoolData.resortid"
                                    >
                                        <option value="">Seleccione Resort</option>
                                        <option
                                            v-for="hotel in hotels"
                                            :key="hotel.id"
                                            :value="hotel.id"
                                        >
                                        {{ hotel.name }}
                                        </option>
                                    </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider name="modalidad del Pool" rules="required">
                                    <b-form-group label="Modalidad del Pool" slot-scope="{ valid, errors }" >
                                        <b-form-select
                                            :state="errors[0] ? false : valid ? true : null"
                                            v-model="rentalPoolData.modepool"
                                        >
                                            <option value="">Seleccione Modalidad</option>
                                            <option
                                            v-for="pool in modalidadPool"
                                            :key="pool.id"
                                            :value="pool.id"
                                            >
                                            {{ pool.name }}
                                            </option>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="FechaInicio">
                                    <b-form-group label="Fecha incio" slot-scope="{ valid, errors }">
                                        <b-form-input
                                            class="form-control"
                                            type="date"
                                            v-model="rentalPoolData.dateStart"
                                            :state="errors[0] ? false : valid ? true : null"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="FechaFinal">
                                    <b-form-group label="Fecha final" slot-scope="{ valid, errors }">
                                        <b-form-input
                                            class="form-control"
                                            type="date"
                                            v-model="rentalPoolData.dateEnd"
                                            :state="errors[0] ? false : valid ? true : null"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                            <div class="float-left pt-2 mt-1">
                                <b-form-checkbox
                                    v-model="rentalPoolData.status"
                                    :value="true"
                                    class="custom-control-success"
                                > Activado
                                </b-form-checkbox>
                            </div>
                            </b-col>
                        </b-row>

                        <div class="mt-1 mb-1 border rounded p-1">
                            <b-row>
                                <b-col md="3"
                                    v-for="(items, index) in rentalPoolData.infohousing"
                                    :key="index"
                                    class="message-box-holder listCondos"
                                >
                                    <b-card :title="items.nameCondo" class="border rounded">
                                        <b-card-text>
                                            <div><strong>Subcondomino: </strong> {{ items.nameSubCondo }}</div>
                                            <div><strong>Numero: </strong> {{ items.countHousing }}</div>
                                        </b-card-text>

                                        <b-button @click="quickHousingShow(items.idCondo, items.idSubCondo)" variant="primary">Ver</b-button>
                                    </b-card>
                                </b-col>
                            </b-row>
                    
                            <b-row>
                            <b-col md="6">
                                    <b-form-select
                                    v-model="selectedCondo"
                                    :clearable="false"
                                    placeholder="Condo"              
                                >
                                    <option selected value="">Seleccione un Condominio</option>
                                    <option v-for="condo in condos" :key="condo.id" :value="condo.id">
                                        {{ condo.name }}
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col md="6">
                                <b-form-select
                                    v-model="selectedSubCondo"
                                    :clearable="false"
                                    placeholder="Condo"
                                    @input="getHousing(true)"
                                >
                                    <option selected value="">Seleccione un Subcondominio</option>
                                    <option
                                        v-for="subcondo in subcondos"
                                        :key="subcondo.id"
                                        :value="subcondo.id"
                                    > {{ subcondo.name }}
                                    </option>
                                </b-form-select>
                            </b-col>
                                    
                            </b-row>
                            <br />
                            <b-row class="mt-1 mb-1 border rounded p-1">

                                <b-col md="12">
                                    <h4>Viviendas</h4>
                                    <b-input v-model="filter" placeholder="Filtros para la tabla.." ></b-input>
                                    <hr />
                                    <b-overlay :show="isDeletingHousingInRentalPool">
                                        <b-table
                                            responsive
                                            sticky-header="400px"
                                            :filter="filter"
                                            :items="optionsHousing"
                                            :fields="fields"
                                            class="mb-0"
                                            v-if="optionsHousing.length"
                                        >
                                        <!-- Optional default data cell scoped slot -->

                                            <template #cell(status)="row">
                                                
                                                <b-form-checkbox
                                                    v-model="row.item.isSelected"
                                                    :value="true"
                                                    switch
                                                    inline
                                                    :unchecked-value="false"
                                                    @change="handlerHousingSelect(row.item, $event)"
                                                    class="custom-control-success"
                                                >
                                                </b-form-checkbox>                                        
                                            </template>
                                        </b-table>
                                    </b-overlay>
                                </b-col>
                            </b-row>
                        </div>
                       
                        <b-row>
                            <b-col>
                                <div class="float-right">
                                    <b-button
                                    type="submit"
                                    class="btn-block"
                                    variant="primary"
                                    :disabled="invalid || isUpdatingRentalPool"
                                    >
                                    <b-spinner small v-if="isUpdatingRentalPool" /> Actualizar
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>                  
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex"
    import Ripple from 'vue-ripple-directive'    
    import HousingNav from "@/modules/fivesClub/components/catalogs/housing/HousingNav"     
    import { showAlertMessage, toJson } from "@/helpers/helpers";
    import { modalidadPool } from "@/data/fivesClub";


    export default {
        directives: {Ripple, },
        components: {
            HousingNav                    
        },
        async created() {
            await this.init()
        },   
        data() {
            return {
                modalidadPool,
                isUpdatingRentalPool: false,
                status: true,
                selectedCondo: null,
                selectedSubCondo: null, 
                idsHousing: [],
                optionsHousing: [], // lista de viviendas que sirve como intermediario antes de mandar a back
                listHousingUpdated: [], // aqui pongo la lista de viviendas que se mandarían a back actualizadas
                filter: null,
                fields: [
                    { key: "status", label: "Agregar a lista", class: "text-center" },
                    { key: "code", label: "Código", class: "text-center" },
                    { key: "name", label: "Nombre", class: "text-center" },
                    { key: "numberHousing", label: "Número de Vivienda", class: "text-center", },
                ],
                rentalPoolData: null,
                isLoadingData: false,     
                isUpdatingRentalPool: false, 
                isDeletingHousingInRentalPool: false,
            };
        },
        computed: {
            ...mapState('start', ['hotels']),
            ...mapState('fivesClubCatalogs', ['condos']),    
        ...mapState('fivesClubCatalogs', [ 'housing', 'condos', 'subCondos' ]),

            ...mapState('auth', ['user']),            
            subcondos(){
                const idCondoSelected = this.selectedCondo               
                if(!idCondoSelected){
                    this.selectedSubCondo =  null 
                }
                return idCondoSelected ? this.subCondos.filter( c => c.condos === idCondoSelected) : []                
            }           
        },
        methods: {
            ...mapActions('fivesClubCatalogs', ['fetchRentalPoolById','updateCondos','getInitialContentRentalPool','fetchGetHousing','deleteHousingInRentalPool','updateRentalPool']),            
            ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setRentalPool']),       
            ...mapMutations('start',['setHotels']),          
            async init(){
				this.isLoadingData = true
                const idRentalPool = this.$route.params.id
                if(this.isANumber(idRentalPool)){
                    await this.getInitialData() //obtengo información necesario para la edicion, condos, hotels subcondos, pool de rentass
                    const rentalPool = await this.fetchRentalPoolById({idRentalPool}) // pido el pool de renta por id que se quiere editar                   
                    this.rentalPoolData = rentalPool[0]   
                    // pongo valores para selectedCondo, selectedSubCondo   que se asociarían a  rentalPoolData             
                    this.setHousingRentalPool() 
                    // para pintar tabla de viviendas, rentalPoolData viene con una listado de viviendas marcadas y se guardan en optionsHousing
                    await this.getHousing() 
                    // del listado de viviendas (optionsHousing) se van marcando los que hacen match con el arreglo de idsHousing
                    this.selectMarkedHomes()
                    // de las viviendas marked, con map los extraigo para crear un nuevo arreglo de viviendas
                    this.createListHousingUpdated()
                } 
				this.isLoadingData = false
            } ,  
            async getInitialData(){
                const { condos, subCondos,  hotels, rentalPool } = await this.getInitialContentRentalPool();
                this.setCondos(condos)
                this.setSubCondos(subCondos)
                this.setRentalPool(rentalPool)
                if(this.hotels.length === 0){
                    this.setHotels(hotels)
                }                
            }, 
            setHousingRentalPool(){
                if( this.rentalPoolData ){
                    this.selectedCondo = this.rentalPoolData.infohousing.length ? this.rentalPoolData.infohousing[0].idCondo : null
                    this.selectedSubCondo = this.rentalPoolData.infohousing.length ? this.rentalPoolData.infohousing[0].idSubCondo : null
                    //arreglo de ids de housings que viene 
                    this.idsHousing = this.rentalPoolData.infohousing.length ? this.rentalPoolData.housings.map( h => h.id ): []
                }
            },                  
            isANumber(string){
                return string ? !isNaN( string ) && string > 0 : false
            }, 
            async update(){
                
                const isConfirmUpdate  = await this.confirmUpdate()

                this.isUpdatingRentalPool = true

                if(isConfirmUpdate){

                    const payload = {
                        idUser: this.user.idUser,
                        idRentalPool: this.rentalPoolData.id,
                        modelpoolid: this.rentalPoolData.modepool,
                        name: this.rentalPoolData.name,
                        datein: this.rentalPoolData.dateStart,
                        dateout: this.rentalPoolData.dateEnd,
                        status: Boolean(this.rentalPoolData.status),
                        housing: toJson(this.listHousingUpdated),
                        resortid: this.rentalPoolData.resortid,
                        status: Boolean(this.rentalPoolData.status),
                    }

                    const { status, message } = await this.updateRentalPool(payload)

                    showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right" )

                    if( status ){
                        await this.init() // refresco todo
                    }
                }
                this.isUpdatingRentalPool = false                
            }, 
            async confirmUpdate() {
                const { isConfirmed } = await this.$swal.fire({                    
                    title: `<p style='font-size:.7em;'>Desea actualizar </p>`,
                    text: "¿Desea actualizar este pool de rentas?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                    reverseButtons: true,
                   
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-danger mr-1",
                    },
                    buttonsStyling: false,
                })
                return isConfirmed
            },          
            async handlerHousingSelect(housing, isChecked) {                
                const { id } = housing   
                const payloadDelete = { idRentalPool: this.rentalPoolData.id, idHousing: id }

                //push in listHousingUpdated
                if( isChecked ){
                    this.listHousingUpdated.push(housing)
                }

                //delete in state listHousingUpdated, así como en back
                if( !isChecked ){
                    this.isDeletingHousingInRentalPool = true                    
                    this.listHousingUpdated = this.listHousingUpdated.filter(b => b.id !== id )
                    await this.deleteHousingInRentalPool(payloadDelete)
                    this.isDeletingHousingInRentalPool = false
                    // await this.init() //
                }                                
            },
        
            async getHousing( executeSelectMarkedHomes = false) {
                if ( this.selectedCondo && this.selectedSubCondo ) {
                    this.optionsHousing = []
                    const payload = { IdCondo: this.selectedCondo, IdSubCondo: this.selectedSubCondo, }
                    this.optionsHousing = await this.fetchGetHousing(payload)    
                    if( executeSelectMarkedHomes ){
                        this.selectMarkedHomes()
                    }                                                  
                }
            },              
            async quickHousingShow(idCondo, idSubcondo){
                this.selectedCondo = idCondo
                this.selectedSubCondo = idSubcondo
                this.optionsHousing = []
                const payload = { IdCondo: idCondo, IdSubCondo: idSubcondo, }
                this.optionsHousing = await this.fetchGetHousing(payload)                        
                this.selectMarkedHomes()                    
            },
            selectMarkedHomes(){
                if( this.optionsHousing.length && this.idsHousing.length){
                    this.optionsHousing.forEach( housing => {
                        housing.isSelected = this.idsHousing.includes(housing.id) // si id del housing está mi arreglo de idsHousing se marcan
                    })
                }
            },
            createListHousingUpdated(){
                if( this.optionsHousing.length && this.idsHousing.length){                    
                    this.listHousingUpdated = this.optionsHousing.filter( h => h.isSelected )
                }     
            }
        },
    };
</script>
<style scoped>
.head-rental{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head-rental h1{
    font-size: 1.5rem;
    color: #000;
}
</style>